exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banki-js": () => import("./../../../src/pages/banki.js" /* webpackChunkName: "component---src-pages-banki-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-poradniki-js": () => import("./../../../src/pages/poradniki.js" /* webpackChunkName: "component---src-pages-poradniki-js" */),
  "component---src-pages-ranking-ubezpieczen-js": () => import("./../../../src/pages/ranking-ubezpieczen.js" /* webpackChunkName: "component---src-pages-ranking-ubezpieczen-js" */),
  "component---src-templates-bank-jsx": () => import("./../../../src/templates/bank.jsx" /* webpackChunkName: "component---src-templates-bank-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-details-jsx": () => import("./../../../src/templates/details.jsx" /* webpackChunkName: "component---src-templates-details-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

